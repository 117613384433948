import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const DistanceService = () => {}

DistanceService.GetEmployeeList = async()=> {  
    return await AXIOS_SERVICE('get', `${BaseUrl}/setting/employee-distance/user`, {})
}

DistanceService.GetEmployeeDistance = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/employee-distance`, {})
}

DistanceService.SaveEmployeeDistance = async(payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/employee-distance/employee-cost`, payload)
} 
 
 export default DistanceService