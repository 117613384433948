<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>ตั้งค่า</b></h3>
        <small> ตั้งค่า > <b>ตั้งค่าการคิดราคาระยะทางพนักงาน</b> </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="employeeDistancePermission=='create' || employeeDistancePermission=='edit'"        
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
          <b-spinner class="align-middle" small v-if="isLoading"></b-spinner>
          <span v-else>
            <feather-icon icon="SaveIcon" />
            บันทึก
          </span>
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-overlay
      :show="isLoading"
      rounded="lg"
      opacity="0.55"
      blur="2px"
      variant="transparent"
      fixed
    >
      <b-row class="mt-1">
        <b-col cols="12">
          <!-- START SECTION FORM วันเดินทาง--->
          <b-card>
            <b-row>
              <b-col md="3" class="">
                <h3><b>ตั้งค่าการคิดราคาระยะทางพนักงาน</b></h3>
              </b-col>
              <b-col></b-col>
              <b-col md="2"  v-if="employeeDistancePermission=='create' || employeeDistancePermission=='edit'">

                  <b-button
                    variant="danger"
                    size="sm"
                    style="height: 35px"
                    class="mr-1"
                    @click="computeAdjustCost('reduce', 0.25)"
                  >
                    - เพิ่ม 0.25
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    style="height: 35px"
                    class="mr-1"
                    @click="computeAdjustCost('add', 0.25)"
                  >
                    + เพิ่ม 0.25
                  </b-button>
                </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col md="3" class="">
                <label><b>ชื่อพนักงาน</b></label>
              </b-col>

              <b-col class="">
                <label><b>ที่อยู่</b></label>
              </b-col>

              <b-col cols="2" class="">
                <label><b>ค่าเดินทาง/ก.ม.</b></label>
              </b-col>
            </b-row>

            <b-row class="mt-1" v-for="emp in employees" :key="emp.userId">
              <b-col md="3" class="">
                <b-form-input
                  placeholder="ชื่อ สกุลพนักงาน"
                  size="sm"
                  :value="`${emp.fname} ${emp.lname}`"
                  readonly
                />
              </b-col>

              <b-col class="">
                <b-form-input
                  placeholder="ที่อยู่"
                  size="sm"
                  readonly
                  :value="`${emp.address}`"
                />
              </b-col>

              <b-col cols="2" class="">
                
                <b-form-input
                  placeholder="2.5"
                  size="sm"
                  value="0"
                  v-model.number="employeeDistanceHolder[emp.userId]"
                />
              </b-col>
            </b-row>
          </b-card>
          <!-- END SECTION FORM วันเดินทาง--->
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import DistanceService from "@/services/setting/distance";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BInputGroup,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      employees: [],
      employeeDistanceHolder: {},
      isLoading: false,
    };
  },
  computed:{
    employeeDistancePermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST007")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },
  mounted() {
    this.getEmployee();
    this.getEmployeeDistance();
  },
  methods: {
    async getEmployee() {
      this.isLoading = true;
      const result = await DistanceService.GetEmployeeList();
      this.employees = result.data.data;
      this.isLoading = false;
    },
    async getEmployeeDistance() {
      this.isLoading = true;
      const result = await DistanceService.GetEmployeeDistance();
      this.restoreDistanceData(result.data.data);
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      const data = this.buildDataBeforeSave();
      const result = await DistanceService.SaveEmployeeDistance(data);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางพนักงาน`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่าระยะทางพนักงาน`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
      this.isLoading = false;
    },
    buildDataBeforeSave() {
      const employees = this.employeeDistanceHolder;
      let output = [];
      Object.keys(employees).forEach((key) => {
        output.push({ userId: parseInt(key), costPerKm: employees[key] });
      });

      return output;
    },
    restoreDistanceData(list) {
      list.map((item) => {
        this.employeeDistanceHolder = {
          ...this.employeeDistanceHolder,
          [item.userId]: item.costPerKm || 0,
        };
      });

      for (let index = 0; index < this.employees.length; index++) {
        const emp = this.employees[index];
        if(this.employeeDistanceHolder[emp.userId]==undefined){
          this.employeeDistanceHolder[emp.userId] = 0
        }
        
      }
    },
    computeAdjustCost(symbol, cost) {
      let empDistance = this.employeeDistanceHolder;
      console.log("employeeDistanceHolder", this.employeeDistanceHolder)

      if (symbol == "add") {    

       for (const key in this.employeeDistanceHolder) {        
          const costPerKm = this.employeeDistanceHolder[key];
          console.log(empDistance[key], costPerKm);
          empDistance[key] = costPerKm +cost          
        }     
      
      } else {
        for (const key in this.employeeDistanceHolder) {        
          const costPerKm = this.employeeDistanceHolder[key];
          console.log(empDistance[key], costPerKm);
          empDistance[key] = costPerKm -cost          
        }
      }
      this.employeeDistanceHolder = empDistance;
    },
 
  },
};
</script>
<style scoped></style>
